@import '../conf';

.dojazd {
    background: $color-background-gray;
    margin-top: 2rem;
    padding-top: 2rem;
    padding-bottom: 4rem;
}
.dojazd__heading {
    text-align: center;
}
.dojazd__wrapper {
    overflow: hidden;
    border-radius: $border-radius;
    box-shadow: 0 5px 35px rgba($color-content, .075);
    line-height: 0;

    iframe {
        width: 100%;
    }
}
.dojazd__buttons {
    text-align: center;
    margin-top: 2rem;
}